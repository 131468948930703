<template>
    <div class="paying_box">
        <img class="wait_box" src="./image/wait.png" alt="">
        <p class="refresh">如已支付成功请<span @click="checkTrade(1)">刷新</span></p>
    </div>
</template>
<script>
import store from 'store';
import { checkOrderStatus } from './api/index';
import { authChecker ,shareCtrl} from '@/common/js/mmcModule';
import utils from "@/common/js/utils";
const params = utils.getRequestParams();
let time;
export default {
    data() {
        return {
            state: 0,
            // 交易单号
            tradeno: '',
            // 支付成功页地址
            nowxsuccess: '',
            // 支付页地址
            payUrl: '',
        }
    },
    mounted() {
        const that = this;
        authChecker.check({ notneedphone: 1 }, () => {
            that.init();
        });
        shareCtrl.init();
    },
    methods: {
        init() {
            this.tradeno = params.tradeno;
            this.nowxsuccess = store.get('nowxsuccess');
            time = setInterval(() => {
                this.checkTrade();
            },1000)
        },
        checkTrade(e) {
            const { tradeno, projuuid } = params;
            const that = this;
            checkOrderStatus(tradeno).then(res => {
                if (res.code == 0) {
                    // alert(that.nowxsuccess.tzUrl)
                    // alert(res.data.is_paid);

                    if(res.data.is_paid !== 0){
                        if(e) {
                            window.location.replace(`${window.BasePath}fund/project/paysuccess?trade_no=${tradeno}&projuuid=${projuuid}&amount=${res.data.amount/100}`)
                        }
                        store.remove('nowxsuccess');
                        store.remove('payUrl');
                        window.location.replace(window.decodeURIComponent(that.nowxsuccess.tzUrl));
                        clearInterval(time);
                    }
                } else {
                    hideLoading();
                }
            });
        }
    },
    destroyed() {
        clearInterval(time);
    }
}
</script>
<style scoped lang="less">
.paying_box {
    .wait_box {
        width: 200px;
    }
    .refresh {
        padding-left: 40px;
        margin-top: 30px;
        font-size: 14px;
        color: #999;
        span {
            position: relative;
            color: #009BFF;
            padding-right: 40px;
            text-decoration: underline;
            &:after{
                content: '';
                width: 14px;
                height: 14px;
                background: url(./image/loop.png);
                background-size: 100% 100%;
                position: absolute;
                display: block;
                right: 20px;
                top: 48%;
                transform: translateY(-50%);
            }
        }
    }
}
</style>
